import React, { useState } from "react";
import { Button } from "../ButtonElements";

import YellowCupcakes from "../../images/Yellow_cupcakes.jpeg";
import CakeImg from "../../images/nutella_cake.jpeg";
import CupcakesImg from "../../images/cupcakes.jpeg";
import {
  HeroContainer,
  HeroBg,
  ImageBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
} from "./HeroElements";

function HeroSection() {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };
  return (
    <HeroContainer id="home">
      <HeroBg>
        <ImageBg src={YellowCupcakes} alt="Background" />
      </HeroBg>
      <HeroContent>
        <HeroH1>Philomena's Kitchen</HeroH1>
        <HeroP>
          Custom cakes, cupcakes and pastry for any occasion. Made with Love.
        </HeroP>
        <HeroBtnWrapper>
          <Button
            to="projects"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
            primary="true"
            dark="true"
            onMouseEnter={onHover}
            onMouseLeave={onHover}
          >
            See my creations {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
}

export default HeroSection;
