export const homeObjOne = {
  id: "about",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "About Me",
  headline: "Who am I?",
  description:
    "A passionate sweet tooth with a love for baking. I am a self-taught baker who has been baking for over 15 years. I specialize in custom cakes, cupcakes and pastry for any occasion. Made with Love.",
  buttonLabel: "Contact Me",
  imgStart: true,
  img: require("../../images/nutella_cake.jpeg"),
  alt: "Car",
  dark: false,
  primary: false,
  darkText: true,
};

export const homeObjTwo = {
  id: "discover",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Projects",
  headline: "Login to your account at any time",
  description:
    "We have you covered no matter where you are located. All you need is an internet connection and a phone or computer.",
  buttonLabel: "Learn More",
  imgStart: true,
  img: require("../../images/nutella_cake.jpeg"),
  alt: "Piggybank",
  dark: false,
  primary: false,
  darkText: true,
};

export const homeObjThree = {
  id: "contact",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Contact Me",
  headline: "Let's get in touch!",
  description: "marybeck226@gmail.com",
  telephone: "(609) 433-9052",
  buttonLabel: "Hire Me",
  imgStart: false,
  img: require("../../images/Sewing_cake.jpeg"),
  alt: "Papers",
  dark: false,
  primary: false,
  darkText: true,
};
