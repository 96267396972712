import styled from "styled-components";

export const ProjectsContainer = styled.div`
  height: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f4cbd0;

  @media screen and (max-width: 1000px) {
    height: 1400px;
  }

  @media screen and (max-width: 768px) {
    height: 2200px;
  }

  @media screen and (max-width: 480px) {
    height: 2200px;
  }
`;

export const ProjectsWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
  padding: 0 50px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`;

export const ProjectsCard = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  max-height: 340px;
  padding: 30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`;

export const ProjectsIcon = styled.img`
  height: 220px;
  width: 190px;
  margin-bottom: 10px;
`;

export const ProjectsH1 = styled.h1`
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 64px;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const ProjectsH2 = styled.h2`
  font-size: 1rem;
  margin-bottom: 10px;
  color: rgb(190, 85, 201);
`;

export const ProjectsP = styled.p`
  font-size: 1rem;
  text-align: center;
  color: #a14a21;
  font-style: italic;
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const Button = styled.button`
  border-radius: 50px;
  background: #010606;
  white-space: nowrap;
  margin: 5px;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: ${({ dark }) => (dark ? "#010606" : "#ffffff")};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fdd719;
    color: #010606;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const PopupImage = styled.img`
  max-width: 50%;
  max-height: 80%;
  z-index: 1001;

  @media screen and (max-width: 768px) {
    max-width: 90%;
    max-height: 80%;
    object-fit: contain;
  }
`;

export const CloseButton = styled.button`
  position: relative;
  z-index: 100000;
  top: 9px;
  right: 0px;
  transform: translate(-140%, 0%);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 30px;
  height: 30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

  &:hover {
    background: #f1f1f1;
  }
`;

export const PopupContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  max-width: 60%;
  max-height: 70%;

  @media screen and (max-width: 768px) {
    max-width: 95%;
  }
`;
