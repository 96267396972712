import React, { useState } from "react";
import CreamCake from "../../images/creamsicle_cake.jpeg";
import GreenCake from "../../images/Green_cake.jpeg";
import FallCake from "../../images/Fall_cake.jpeg";
import EngagementCake from "../../images/engagement cake.jpeg";
import HolySpiritCake from "../../images/HolySpirit_Cake.jpeg";
import GreenOrangeCake from "../../images/GreenOrangeCake.jpeg";
import BirthdayCake from "../../images/winter .jpeg";
import NutellaCake from "../../images/nutella_cake.jpeg";

import {
  ProjectsContainer,
  ProjectsH1,
  ProjectsWrapper,
  ProjectsCard,
  ProjectsIcon,
  ProjectsH2,
  ProjectsP,
  Overlay,
  PopupImage,
  CloseButton,
  PopupContainer,
} from "./ProjectsElements";

const Gallery = ({ buttonLabel, primary, dark, dark2 }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  const handleImageClick = (imageSrc) => {
    setCurrentImage(imageSrc);
    setIsPopupOpen(true);
  };

  return (
    <ProjectsContainer id="projects">
      <ProjectsH1>Gallery</ProjectsH1>
      <ProjectsWrapper>
        <ProjectsCard>
          <ProjectsIcon
            onClick={() => handleImageClick(FallCake)}
            src={FallCake}
          />
          <ProjectsP>
            Two tiered chocolate cake stuffed with Oreo mouse and chocolate
            ganache
          </ProjectsP>
        </ProjectsCard>
        <ProjectsCard>
          <ProjectsIcon
            onClick={() => handleImageClick(EngagementCake)}
            src={EngagementCake}
          />
          <ProjectsP>
            Six inch vanilla chocolate chip cake with Nutella and strawberry
            mousse
          </ProjectsP>
        </ProjectsCard>
        <ProjectsCard>
          <ProjectsIcon
            onClick={() => handleImageClick(GreenOrangeCake)}
            src={GreenOrangeCake}
          />
          <ProjectsP>
            {" "}
            12 inch round chocolate layers with Oreo mousse filling and orange
            frosting
          </ProjectsP>
        </ProjectsCard>
        <ProjectsCard>
          <ProjectsIcon
            onClick={() => handleImageClick(HolySpiritCake)}
            src={HolySpiritCake}
          />

          <ProjectsP>
            12 inch round chocolate cake, filled with creamy Nutella mousse and
            salted caramel sauce
          </ProjectsP>
        </ProjectsCard>

        <ProjectsCard>
          <ProjectsIcon
            onClick={() => handleImageClick(BirthdayCake)}
            src={BirthdayCake}
          />
          <ProjectsP>
            6 inch strawberry birthday cake filled with fresh strawberry jam and
            strawberry mousse
          </ProjectsP>
        </ProjectsCard>
        <ProjectsCard>
          <ProjectsIcon
            onClick={() => handleImageClick(NutellaCake)}
            src={NutellaCake}
          />
          <ProjectsP>
            Chocolate with Nutella and toasted marshmallow filling top tier.
            Vanilla with strawberries bottom
          </ProjectsP>
        </ProjectsCard>
      </ProjectsWrapper>
      {isPopupOpen && (
        <Overlay onClick={() => setIsPopupOpen(false)}>
          <PopupContainer onClick={(e) => e.stopPropagation()}>
            {" "}
            {/* Prevents the overlay click event from closing the popup when the container is clicked */}
            <PopupImage src={currentImage} />
            <CloseButton onClick={() => setIsPopupOpen(false)}>×</CloseButton>
          </PopupContainer>
        </Overlay>
      )}
    </ProjectsContainer>
  );
};

export default Gallery;
