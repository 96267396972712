import React from "react";
import Cake from "../../images/nutella_cake.jpeg";
import PumpkinCake from "../../images/Pumpkin_cake.jpeg";
import Cookies from "../../images/cookies valentines.jpeg";
import GreenCake from "../../images/Green_cake.jpeg";
import Cupcakes from "../../images/cupcakes.jpeg";
import BaptismCake from "../../images/baptism cake.jpeg";
import CatCake from "../../images/Cat_cake.jpeg";
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
} from "./ServicesElements";

const Services = () => {
  return (
    <ServicesContainer id="services">
      <ServicesH1>Services</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Cake} />
          <ServicesH2>Wedding Cakes</ServicesH2>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={GreenCake} />
          <ServicesH2>Birthday Cakes</ServicesH2>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={CatCake} />
          <ServicesH2>Baby Shower</ServicesH2>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Cupcakes} />
          <ServicesH2>Cupcakes</ServicesH2>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={BaptismCake} />
          <ServicesH2>Baptisms</ServicesH2>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Cookies} />
          <ServicesH2>Cookies</ServicesH2>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
